import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class BulkService extends ApiService {

  protected override endpoint: string = 'bulk/'

  public process(namespace: string, operation: string, data: object = {}): Observable<any> {
    return this._post(
      data,
      this.endpoint + namespace + '/' + operation,
    )
  }

}
